/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { useRef } from 'react';
import { CookieConsentLink } from 'volto-cookie-consent-addon';

const Footer = () => {
  const consentRef = useRef();
  return (
    <div className="footer-container">
      <div className="footer-consent-link">
        <CookieConsentLink consentRef={consentRef} />
      </div>
      <div className="footer-consent-main" ref={consentRef} />
    </div>
  );
};

export default Footer;
