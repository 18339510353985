import Matomo from '@package/components/theme/Matomo/Matomo';

import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,

    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: Matomo,
      },
    ],

    defaultPageSize: 100,
    isMultilingual: true,
    supportedLanguages: ['fi'],
    defaultLanguage: 'fi',
  };
  return config;
}
